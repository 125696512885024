import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { pickingCompletedChange } from "../../../../api";
import Navbar from "../../../../containers/navbar";
import MainHeader from "../../../../containers/header";
import Header from "../../../../containers/content_header";
import ReportTemplate from "../../../../containers/report_template";
import ErrorModal from "../../../../containers/error_modal";

const FTPickingCompleted = ({ ...props }) => {
  const { loc, locationName, widthIsSmall, username } = props;
  const [pickingCompletedList, setPickingCompletedList] = useState([]);
  const [allPickingCompletedList, setAllPickingCompletedList] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [itemKeys, setItemKeys] = useState([]);
  const [lambdaError, setLambdaError] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  const [isdownloadLoading, setIsdownloadLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const openErrRef = useRef();
  const closeErrRef = useRef();
  const tableHeads = [
    "Order No.",
    "Store",
    "Item",
    "Item Desc",
    "yellow Lpn",
    "Lpn",
    "Lpn Type",
    "Lpn Qty",
    "Create Date",
    "user id",
  ];

  const isFirstRender = useRef(true);

  useEffect(() => {
    getPickingCompletedSummary();

    if (isFirstRender.current) {
      isFirstRender.current = false
      return;
    }
  }, [currentPage, searchText]);

  const getPickingCompletedSummary = async () => {
    try {
      setPickingCompletedList([]);
      setIsListLoading(true);
      let payload = {
        loc: loc,
        start: currentPage * 10,
        length: 10,
        search_key: searchText,
        file: false,
        report_type: "ft",
        user_id: String(username),
      };
      const resData = await pickingCompletedChange(payload);

      if (resData) {
        if (resData?.status === "error") {
          setLambdaError(resData?.error_message);
          openErrRef.current.click();
        } else {
          if (Object.keys(resData?.body?.records)?.length) {
            let formattedData = resData?.body?.records?.map((item) => {
              return {
                order_no: item?.order_no,
                store: item?.store,
                item: item?.item,
                item_desc: item?.item_desc,
                yellow_lpn: item?.yellow_lpn,
                lpn: item?.lpn,
                lpn_type: item?.lpn_type,
                lpn_qty: item?.lpn_qty,
                create_date: item?.create_date,
                user_id:item?.user_id
              };
            });
            setPickingCompletedList(formattedData);
            setAllPickingCompletedList(formattedData);
            setfilteredData(formattedData);
            setItemKeys(Object.keys(formattedData[0]));
            setTotalRecords(resData?.body?.totalRecords);
          } else {
            setTotalRecords(resData?.body?.totalRecords);
          }
        }
        setIsListLoading(false);
      } else {
        // setLambdaError("Something went wrong");
        // openErrRef.current.click();
        setIsListLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsListLoading(false);
    }
  };

  const onDownLoadS3Url = async () => {
    try {
      setIsdownloadLoading(true);
      let payload = {
        loc: loc,
        start: 0,
        length: 0,
        search_key: searchText,
        file: true,
        report_type: "ft",
        user_id: String(username),
      };
      const resData = await pickingCompletedChange(payload);

      if (resData) {
        if (resData?.status === "error") {
          setLambdaError(resData?.error_message);
          openErrRef.current.click();
        } else {
          if (resData?.body?.file_url) {
            window.open(resData?.body?.file_url);
          }
        }
      }
      // else {
      //   setLambdaError("Something went wrong");
      //   openErrRef.current.click();
      // }
      setIsdownloadLoading(false);
    } catch (error) {
      console.log(error);
      setLambdaError(error);
      openErrRef.current.click();
      setIsdownloadLoading(false);
    }
  };

  const addPageData = (pageData) => {
    setPickingCompletedList(pageData);
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="lpn-hm d-flex flex-column"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <Header
          text={`${loc} - ${locationName}`}
          headerText={"FT Picking Completed"}
        />
        <ReportTemplate
          widthIsSmall={widthIsSmall}
          tableHeads={tableHeads}
          itemKeys={itemKeys}
          data={pickingCompletedList}
          isTableLoading={isListLoading}
          loc={loc}
          buttonName={"Download FT Picking Completed List"}
          filePrefix={"ft_picking_completed"}
          searchText={(text) => setSearchText(text)}
          allData={allPickingCompletedList}
          addPageData={addPageData}
          pageCount={10}
          filteredData={filteredData}
          totalRecords={totalRecords}
          type={"paginationFromDb"}
          changePage={(pageNo) => setCurrentPage(pageNo)}
          onDownloadUrl={onDownLoadS3Url}
          isdownloadLoading={isdownloadLoading}
        />
        <ErrorModal
          id={"errorModal"}
          openRef={openErrRef}
          closeRef={closeErrRef}
          headerColor={"red"}
          msg={lambdaError}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loc: state?.auth?.user?.loc,
    locationName: state?.auth?.user?.locationName,
    widthIsSmall: state?.auth?.widthIsSmall,
    username: state?.auth?.user?.loginName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FTPickingCompleted);
