import { useState } from "react";
import { downloadAPK, listAppVersions } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";

const AppVersions = ({ locs, stores, onAppNameChange }) => {
  const [appIds, setAppIds] = useState([]);

  // const [appId, setAppId] = useState("")
  const [isLoading, setIsLoading] = useState();
  const [appName, setAppName] = useState("")
  const [selectedLoc, setSelectedLoc] = useState("")

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      appId: "",
      loc: "",
    },
  });

  const getAllAppIds = async (data) => {
    try {
      setAppIds([]);
      setIsLoading(true);
      const versionLists = await listAppVersions({
        loc: data?.loc,
        app_id: data?.appId,
      });
      if (versionLists?.data?.length) {
        setAppIds(versionLists?.data);
      } else {
        invokeToast(versionLists?.data?.message, "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onDownload = async (appId, version) => {
    try {
      const downloadUrl = await downloadAPK(appId, version, getValues("loc"));
      if (downloadUrl?.data?.url) {
        window.open(downloadUrl.data.url);
      } else {
        invokeToast(downloadUrl?.data?.message, downloadUrl?.data?.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const invokeToast = (msg, type) => {
    type === "success"
      ? toast.success(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      : toast.error(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
  };

  return (
    <div>
      <div className="align-items-center app-table d-flex flex-column m-3">
        <form className="w-25" onSubmit={handleSubmit(getAllAppIds)}>
          {/* <div className="d-flex flex-column mb-3">
            <div className="d-flex align-items-baseline">
              <div className="header-text text-nowrap">
                Select Version Type :&nbsp;
              </div>
              <select
                {...register("versionType", { required: true })}
                className={[
                  "form-select role-select form-select-md",
                  errors.versionType?.type === "required" && "border-red",
                ].join(" ")}
              >
                <option disabled value={""}>
                  Select Version
                </option>
                <option value={"debug"}>debug</option>
                <option value={"release"}>release</option>
              </select>
            </div>
            {errors.versionType?.type === "required" && (
              <div className="error-text ms-auto">Version Type is required</div>
            )}
          </div> */}
          <div className="d-flex flex-column mb-4 mt-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="header-text text-nowrap">
                Select App &nbsp;:&nbsp;
              </div>
              <select
                {...register("appName", { required: true })}
                onChange={(e) => {
                  setAppName(e.target.value)
                  onAppNameChange(e.target.value)
                }}
                value={appName}
                className={[
                  "form-select role-select form-select-md w-50",
                  errors.appName?.type === "required" && "border-red",
                ].join(" ")}
              >
                <option disabled value={""}>
                  Select App
                </option>
                <option value={"wms"}>WMS+</option>
                <option value={"simplus"}>SIM+</option>
                <option value={"rnm"}>RNM Technician</option>
                <option value={"att"}>Attendance</option>

              </select>
            </div>
            {errors.appName?.type === "required" && (
              <div className="error-text ms-auto">App Name is required</div>
            )}
          </div>

          <div className="d-flex flex-column mb-4 mt-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="header-text">App ID :</div>
              <input
                type={"text"}
                className={[
                  "form-control upload-form w-50",
                  errors.appId?.type === "required" && "border-red",
                ].join(" ")}
                placeholder="Enter App ID"
                {...register("appId", { required: true })}
              />
            </div>
            {errors.appId?.type === "required" && (
              <div className="error-text ms-auto">App ID is required</div>
            )}
          </div>
          {/* {appName === "wms" &&
            <div className="d-flex flex-column mt-3">
              <div className="d-flex align-items-baseline">
                <div className="header-text text-nowrap">
                  Select DC Location &nbsp;:&nbsp;
                </div>
                <select
                  {...register("loc", { required: true })}
                  className={[
                    "form-select role-select form-select-md",
                    errors.loc?.type === "required" && "border-red",
                  ].join(" ")}
                >
                  <option disabled value={""}>
                    Select Loc
                  </option>
                  {locs?.map((loc, i) => (
                    <option key={i} value={loc?.loc}>
                      {loc?.loc}
                    </option>
                  ))}
                </select>
              </div>
              {errors.loc?.type === "required" && (
                <div className="error-text ms-auto">Loc is required</div>
              )}
            </div>
          } */}
          {appName === "wms" &&
            <div className="d-flex flex-column justify-content-between mt-2 w-100">
              <div className="d-flex align-items-baseline justify-content-between">
                <div className="header-text ">
                  Location :
                </div>
                <Dropdown
                  {...register("loc", { required: true })}
                  value={selectedLoc}
                  options={locs}
                  onChange={(e) => setSelectedLoc(e.value)}
                  optionLabel="loc"
                  // placeholder="Select a Location"
                  filter
                  showClear
                  className="w-50"
                />
              </div>
              {errors.loc?.type === "required" && (
                <div className="error-text ms-auto">Loc is required</div>
              )}
            </div>
          }

          {/* {appName === "simplus" &&
            <div className="d-flex flex-column mt-3">
              <div className="d-flex align-items-baseline">
                <div className="header-text text-nowrap">
                  Select Store Location &nbsp;:&nbsp;
                </div>
                <select
                  {...register("loc", { required: true })}
                  className={[
                    "form-select role-select form-select-md",
                    errors.loc?.type === "required" && "border-red",
                  ].join(" ")}
                >
                  <option disabled value={""}>
                    Select Loc
                  </option>
                  {stores?.map((loc, i) => (
                    <option key={i} value={loc?.loc}>
                      {loc?.loc}
                    </option>
                  ))}
                </select>
              </div>
              {errors.loc?.type === "required" && (
                <div className="error-text ms-auto">Loc is required</div>
              )}
            </div>} */}

          {(appName === "simplus" || appName === "rnm"|| appName === "att") &&
            <div className="d-flex flex-column justify-content-between mt-2 w-100">
              <div className="d-flex align-items-baseline justify-content-between">
                <div className="header-text ">
                  Stores :
                </div>
                <Dropdown
                  {...register("loc", { required: true })}
                  value={selectedLoc}
                  options={stores}
                  onChange={(e) => setSelectedLoc(e.value)}
                  optionLabel="loc"
                  // placeholder="Select a Location"
                  filter
                  showClear
                  className="w-50"
                />
              </div>
              {errors.loc?.type === "required" && (
                <div className="error-text ms-auto">Store is required</div>
              )}
            </div>
          }

          <div className="d-flex justify-content-center mt-4">
            <button className="btn btn-primary">Get Versions</button>
          </div>
        </form>
        {(appIds?.length && (
          <>
            <div className="border-bottom w-100 my-5 border-light"></div>
            <table className="table">
              <thead>
                <tr>
                  <th>Version</th>
                  <th>Release Notes</th>
                  <th>Get APK</th>
                </tr>
              </thead>
              <tbody>
                {appIds?.map((app, i) => (
                  <tr key={i}>
                    <td>{app?.version}</td>
                    <td>{app?.release_notes}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          onDownload(
                            getValues("appId"),
                            app?.version
                          )
                        }
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {isLoading && (
              <div
                className="d-flex justify-content-center"
                style={{ marginTop: "5%" }}
              >
                <div className="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            {!appIds?.length && !isLoading && (
              <div className="d-flex justify-content-center w-100 no-records">
                No records found
              </div>
            )}
          </>
        )) ||
          ""}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
export default AppVersions;
