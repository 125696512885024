import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { locList, storeList } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../../containers/navbar";
import MainHeader from "../../containers/header";
import AppVersions from "../../containers/app_versions";
import LatestApk from "../../containers/latest_apk";
import UploadApk from "../../containers/upload_apk";
import "./apk_uploader.css";

const ApkUploader = ({ ...props }) => {
  const { widthIsSmall } = props;

  const [currentScreen, setCurrentScreen] = useState("id");
  const [locs, setLocs] = useState([]);
  const [stores, setStores] = useState([]);
  const [appName, setAppName] = useState('')

  useEffect(() => {
    if (appName === "wms")
      getLocList();
    if (appName === "simplus" || appName === "rnm" || appName === "att")
      getStoreList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appName]);

  const getLocList = async () => {
    try {
      const locationList = await locList();
      if (locationList?.body?.length) {
        setLocs(locationList?.body);
      } else {
        invokeToast("No Locs Found", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getStoreList = async () => {
    try {
      const storesList = await storeList();
      if (storesList?.body?.length) {
        setStores(storesList?.body);
      } else {
        invokeToast("No Store Found", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const invokeToast = (msg, type) => {
    type === "success"
      ? toast.success(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      : toast.error(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
  };

  return (
    <>
      <MainHeader />
      <Navbar />
      <div
        className="au d-flex flex-column"
        style={{ padding: widthIsSmall ? "0px" : "8px 24px" }}
      >
        <div className="au-tabs d-flex flex-column">
          <ul className="nav nav-tabs d-flex justify-content-center">
            <li className="nav-item">
              <button
                className={[
                  "nav-link au-nav",
                  currentScreen === "id" && "active",
                ].join(" ")}
                onClick={() => setCurrentScreen("id")}
              >
                GET VERSIONS
              </button>
            </li>
            <li className="nav-item">
              <button
                className={[
                  "nav-link au-nav",
                  currentScreen === "latest" && "active",
                ].join(" ")}
                onClick={() => setCurrentScreen("latest")}
              >
                GET LATEST APK
              </button>
            </li>
            <li className="nav-item">
              <button
                className={[
                  "nav-link au-nav",
                  currentScreen === "upload" && "active",
                ].join(" ")}
                onClick={() => setCurrentScreen("upload")}
              >
                UPLOAD APK
              </button>
            </li>
          </ul>
          {currentScreen === "id" && (
            <div className="au-content">
              <AppVersions locs={locs} stores={stores} onAppNameChange={(value) => setAppName(value)} />
            </div>
          )}
          {currentScreen === "latest" && (
            <div className="au-content">
              <LatestApk locs={locs} stores={stores} onAppNameChange={(value) => setAppName(value)} />
            </div>
          )}
          {currentScreen === "upload" && (
            <div className="align-items-center au-content d-flex justify-content-center">
              <UploadApk locs={locs} stores={stores} onAppNameChange={(value) => setAppName(value)} />
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    widthIsSmall: state?.auth?.widthIsSmall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ApkUploader);
